<template>
  <div
    class="shadow-md rounded-md lg:px-20 px-5 lg:py-14 py-10 bg-white w-full"
    :style="{'max-width': '650px'}"
  >
    <div
      class="flex lg:flex-row flex-col lg:justify-between justify-center lg:items-end items-center"
    >
      <p
        class="lg:text-4xl text-3xl text-ash-800 lg:mr-20 mr-0 mb-2"
      >
        會員註冊
      </p>
      <div>
        <div
          class="flex items-center lg:pb-3 pb-0"
        >
        <router-link
          :to="{
            name: 'Login'
          }"
          class="gtm-forgot-link flex items-center group"
        >
          <DgIcon
            size="7"
            name="chevron-left"
            color="ash-600"
            class="mr-3"
            hoverColor="primary"
          />
          <p
            class="text-base text-ash-600 whitespace-nowrap group-hover:text-primary"
          >返回登入</p>
        </router-link>
        <span
          class="text-ash-600 px-4"
        >|</span>
        <router-link
          :to="{
            name: 'Home'
          }"
          class="gtm-forgot-link"
        >
          <span
            class="text-ash-600 text-center hover:text-primary"
          >返回下載列表</span>
        </router-link>
        </div>
      </div>
    </div>
    <p class="my-5 text-center">
      一次註冊，鼎新官網+就享知 兩大數位知識寶庫隨你取用！
    </p>
    <DgForm
      ref="form"
      class="my-10"
      :settings="settings"
    />
    <!-- <div
      class="flex lg:flex-row flex-col justify-between items-center my-10"
    >
      <p
      class="text-sm text-ash-600"
      >或使用社群帳號登入</p>
      <DgSeparator
        size="143"
        class="lg:block hidden"
      />
    </div> -->
    <button
      class="gtm-signup-btn bg-primary-gradient-to-r py-2 text-lg text-white rounded-full w-full"
      @click="methodSignUp"
    >註冊</button>
    <div
      class="mt-9 text-sm text-ash-600 text-center"
    >
      <!-- ※ 註冊成功後下載文件將立即發送至所提供之 email 信箱 -->
      <p class="text-red-700 text-base">註冊成功後下載文件將立即發送至所提供之email信箱<br>請務必填寫正確email</p>
      <br>
      送出註冊即同意
      <a href="https://www.digiwin.com/tw/legal.html" target="_blank">
        <p
          class="text-sm text-ash-600 underline inline"
        >隱私權政策</p>
      </a>
      ，並同意成為就享知知識平台的會員
    </div>
  </div>
</template>

<script>
import { mapMutations, mapActions } from 'vuex';
import DgIcon from '@/components/base/DgIcon.vue';
// import DgSeparator from '@/components/base/DgSeparator.vue';
import DgForm from '@/components/dgForm/Index.vue';
// import PlanSetting from '@/views/signup/PlanSetting.vue';
import { isEqual } from '@/lib/lodash';
import {
  required, checkMail, checkPhone,
} from '@/utils/validator';
import requestApi from '@/lib/http';

export default {
  name: 'SignupCard',
  components: {
    DgIcon,
    // DgSeparator,
    DgForm,
  },
  data() {
    return {
      loginThirdList: {
        facebook: '',
        line: '',
        google: '',
      },
      settings: [
        {
          componentType: 'text',
          col: '2',
          inputType: 'text',
          uniKey: 'realname',
          placeholder: '請輸入真實中文姓名',
          rules: [
            required,
          ],
        },
        {
          componentType: 'text',
          col: '2',
          inputType: 'text',
          uniKey: 'company_name',
          placeholder: '請輸入完整公司名稱',
          rules: [
            required,
          ],
        },
        {
          componentType: 'text',
          col: '2',
          inputType: 'email',
          uniKey: 'email',
          placeholder: '請輸入 email',
          rules: [
            required,
            checkMail,
          ],
        },
        {
          componentType: 'text',
          col: '2',
          inputType: 'tel',
          uniKey: 'phone',
          placeholder: '請輸入手機號碼',
          rules: [
            required,
            checkPhone,
          ],
        },
        {
          componentType: 'text',
          col: '2',
          inputType: 'password',
          uniKey: 'password',
          placeholder: '請輸入8~12位之密碼',
          rules: [
            required,
          ],
        },
        {
          componentType: 'text',
          col: '2',
          inputType: 'password',
          uniKey: 'passwordValid',
          placeholder: '請再次輸入密碼',
          errorMsg: 'ddd',
          rules: [
            required,
            (v, cb) => isEqual(this.$refs?.form?.value?.password, v) || cb('兩次密碼不相同'),
          ],
        },
        // {
        //   componentType: 'text',
        //   col: '2',
        //   uniKey: 'inv_code',
        //   placeholder: '有好友邀請碼？請在此輸入',
        //   rules: [
        //   ],
        // },
      ],
    };
  },
  methods: {
    ...mapActions(['setRegisterToken']),
    ...mapMutations({
      showNotifySuccess: 'setNotifySuccess',
      showNotifyError: 'setNotifyError',
      mutationSetModal: 'setModal',
    }),
    async methodSignUp() {
      const params = this.$refs.form.methodGetContent();
      if (!params) {
        this.showNotifyError('註冊失敗，請檢查錯誤提示');
        return;
      }
      delete params.passwordValid;

      const { status, message/* result */ } = await requestApi('users.signUp', params);
      if (status) {
        // const { token = false } = result?.data ?? {};
        // const isSetup = await this.actionHandleLoginInfo({ token });
        // console.log('isSetup', isSetup);
        // if (isSetup) {
        //   this.methodGo({
        //     name: 'Home',
        //   });
        // }

        // this.setRegisterToken(result.data.token);

        // const { token = false } = result?.data ?? {};
        // console.log('token:', token, 'result', result);
        // const isSetup = await this.setRegisterToken({ token });

        // console.log('isSetup', isSetup);

        // this.mutationSetModal({
        //   show: true,
        //   propsData: {
        //     value: {},
        //   },
        //   persistent: true,
        //   closeButton: false,
        //   contentComponent: PlanSetting,
        // });
        this.showNotifySuccess('註冊成功');
        this.$router.push({ name: 'Login' });
      } else {
        this.showNotifyError(`註冊失敗，${message}`);
      }
    },
  },
};
</script>
