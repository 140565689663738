<template>
  <div
    class="bg-ash-400 bg-opacity-10 flex items-center"
  >
    <div
      class="max-w-1500 mx-auto px-5 py-5"
    >
      <!-- left -->
      <!-- <div
        class="lg:flex hidden flex-col justify-center pl-11"
      >
        <DgImg
          src="logo/imgLogo"
          :style="{width: '135px'}"
        />
        <p
          class="text-3xl text-ash-800 mt-3"
        >擁抱知識，成就自己</p>
        <DgImg
          src="imgs/imgSignup"
          class="mt-10 -ml-14"
          :style="{width: '694px'}"
        />
      </div> -->
      <!-- right -->
      <div class="pb-3">
        <DgImg
          src="logo/imgLogo"
          :style="{width: '200px'}"
        />
      </div>
      <div>
        <SignupCard />
      </div>
    </div>
  </div>
</template>

<script>
// import DgImg from '@/components/base/DgImg.vue';
import SignupCard from '@/views/signup/SignupCard.vue';
import DgImg from '../../components/base/DgImg.vue';

export default {
  name: 'Signup',
  components: {
    // DgImg,
    SignupCard,
    DgImg,
  },
  data() {
    return {

    };
  },
  methods: {

  },
};
</script>
